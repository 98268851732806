<template>
  <div>
    <keep-alive include="PopularScienceDemo">
      <router-view style="height: 76vh;"></router-view>
    </keep-alive>
  </div>
</template>

<script>
export default {
  name: "ysHome"
}
</script>

<style scoped>

</style>
